<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-04-18 09:52:56
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-06-09 17:08:11
-->
<template>
    <div class="box-wrap">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">投票活动</div>
        </div>
        <div class="case-wrap">
            <div class="add-wrap">
                <el-button type="primary"
                    @click="isAddScenarios = true">添加活动</el-button>
            </div>
            <el-table v-loading="loading" border :data="tableData" style="width: 100%;">
                <el-table-column align="center" label="图片集合" width="120">
                    <template slot-scope="scope">
                        <el-image fit="contain" class="image"
                            :preview-src-list="scope.row.imgs" :src="scope.row.imgs[0]"/>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="活动名称" prop="name"></el-table-column>
                <el-table-column align="center" label="投票项介绍" prop="content" width="120">
                    <template slot-scope="scope">
                        <img @click="clickImage(0,scope.row)" class="image" :src="scope.row.content" alt="">
                    </template>
                </el-table-column>
                <el-table-column align="center" label="每天投票次数" prop="daily_vnum"></el-table-column>
                <el-table-column align="center" label="浏览量" prop="cnt_look"></el-table-column>
                <el-table-column align="center" label="总投票量" prop="cnt_vote"></el-table-column>
                <el-table-column align="center" label="总投票人数" prop="cnt_people"></el-table-column>
                <el-table-column align="center" label="开启时间" prop="open_time"></el-table-column>
                <el-table-column align="center" label="关闭时间" prop="close_time"></el-table-column>
                <el-table-column align="center" label="是否开启" >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.state"
                            @change="changeLowerup(scope.row)"
                           >
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="320">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary"
                        @click="handleInfo(scope.row)">投票项</el-button>
                        <el-button size="mini" type="success"
                        @click="handleLook(scope.row)">二维码</el-button>
                        <el-button size="mini"
                        @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button size="mini" type="danger"
                        @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-wrap">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="10"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <!-- 添加 -->
        <el-dialog
            :before-close="handleCloseScenarios" 
            :close-on-click-modal="false"
            :visible.sync="isAddScenarios" 
            custom-class="dialog-wrap">
            <div v-if="editId<0" class="title">添加活动</div>
            <div v-else class="title">编辑活动</div>
            <div class="content-wrap" v-loading="addLoading">
                <el-form :model="scenariosForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
                    <el-form-item label="活动名称" prop="name">
                        <el-input v-model="scenariosForm.name" placeholder="请输入活动名称"></el-input>
                    </el-form-item>
                    <el-form-item label="图片集合" prop="imgs">
                        <div class="upload-wrap">
                            <aliyun-upload 
                                :limitSize="10" :limit="5" :multiple="true" :isShopTip="false" :sizePx="true"
                                :btnDisplay="image.length<5" listType="picture-card"
                                v-model="image" :validWidth="1125" :validHeight="1200">
                            </aliyun-upload>
                            <div  class="image-wrap">
                                <div v-for="(item,index) in image" :key="index">
                                    <div v-if="image.length>0" class="fileList-box">
                                        <div @click="delImageList(index)" class="delFile">x</div>
                                        <el-image fit="contain" class="pic"
                                            :preview-src-list="image" :src="image[index]"/>
                                    </div>
                                </div>
                            </div>
                            <div>上传图片大小不超过10M ，分辨率为1125 * 1200</div>
                        </div>
                    </el-form-item>
                    <el-form-item label="投票项介绍" prop="content">
                        <div class="upload-wrap">
                            <aliyun-upload 
                                :limitSize="10" :limit="1" :isShopTip="false" :heightAuto="true"
                                :btnDisplay="content.length<1" listType="picture-card"
                                v-model="content" :validWidth="1125">
                            </aliyun-upload>
                            <div v-if="content.length>0" class="fileList-box">
                                <div @click="delContentList" class="delFile">x</div>
                                <el-image fit="contain" class="pic"
                                    :preview-src-list="content" :src="content[0]"/>
                            </div>
                            <div>上传图片大小不超过10M ，宽度必须为1125</div>
                        </div>
                    </el-form-item>
                    <el-form-item label="每天投票次数" prop="daily_vnum">
                        <el-input v-model="scenariosForm.daily_vnum" placeholder="请输入活动描述"></el-input>
                    </el-form-item>
                    <el-form-item label="活动时间" prop="close_time">
                        <el-date-picker
                            v-model="dateValue"
                            type="datetimerange"
                            @change="dateChange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <div class="btn-wrap">
                        <el-button v-if="editId<0" :loading="btnLoading" type="primary" @click="submitForm">立即创建</el-button>
                        <el-button v-else :loading="btnLoading" type="primary" @click="submitFormEdit">编 辑</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
        <el-dialog
            :before-close="handleCloseScenarios" 
            :close-on-click-modal="false"
            :visible.sync="isShow" 
            custom-class="dialog-code-wrap">
            <div class="content-wrap">
                <img width="200" height="200" :src="lookUrl" alt="">
                <el-button type="primary" class="btn"
                    @click="clickDow(lookUrl)">下载二维码</el-button>
            </div>
        </el-dialog>
        <div v-if="isShowImages">
            <el-image-viewer style="z-index: 99999"
            :on-close="closeViewer" 
            :url-list="imageList" />
        </div>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import {dealdate} from "@/utils/timeCycle.js"
import AliyunUpload from "@/components/AliyunUpload.vue";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
function download(href, name) {
  let eleLink = document.createElement('a')
  eleLink.download = name
  eleLink.href = href
  eleLink.click()
  eleLink.remove()
}
export default {
    components:{
        AliyunUpload,
        ElImageViewer
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'votingActivity',
            isAddScenarios:false,
            isShow: false,
            isShowImages: false,
            currentPage: 1,
            total: 0,
            scenariosForm: {
                name: '',
                imgs: [],
                daily_vnum: 1,
                content: '',
                open_time: '',
                close_time: ''
            },
            dateValue: [],
            rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                ],
                content: [
                    { required: true, message: '请上传投票项介绍', trigger: 'blur' }
                ],
                imgs: [
                    { required: true, message: '请上传图片集合', trigger: 'blur' },
                ],
                daily_vnum: [
                    { required: true, message: '请填写投票次数', trigger: 'blur' },
                ],
                close_time: [
                    { required: true, message: '请选择时间', trigger: 'blur' },
                ],
            },
            loading: false,
            btnLoading: false,
            addLoading: false,
            image: [],
            content: [],
            background: [],
            editId: -1,
            tableData: [],
            imageList: [],
            isSwitch: false,
            lookUrl: ''
        }
    },
    mounted () {
        this.getList();
    },
    watch: {
        image: {
            handler(newval, oldval) {
                if(newval.length > 0){
                    this.scenariosForm.imgs = JSON.stringify(newval)
                }
            },
            deep: true
        },
        content: {
            handler(newval, oldval) {
                if(newval.length > 0){
                    this.scenariosForm.content = newval[0]
                }
            },
            deep: true
        }
    },
    methods: {
        // 点击图片预览
        clickImage(index,row){
            this.imageList = []
            this.isShowImages = true;
            this.imageList.push(row.content);
        },
        // 关闭查看器
        closeViewer() {
            this.isShowImages = false
        },
        handleInfo(row){
            this.$router.push("/singleChoice");
            localStorage.setItem('singleId',row.id);
        },
        clickDow(url) {
            let image = new Image()
            image.setAttribute('crossOrigin', 'anonymous')
            image.src = url
            image.onload = () => {
            let canvas = document.createElement('canvas')
            canvas.width = image.width
            canvas.height = image.height
            let ctx = canvas.getContext('2d')
            ctx.drawImage(image, 0, 0, image.width, image.height)
            canvas.toBlob((blob) => {
                let url = URL.createObjectURL(blob)
                download(url,'二维码')
                // 用完释放URL对象
                URL.revokeObjectURL(url)
            })
            }
        },
        changeLowerup(row){
            this.loading = true;
            common.connect("/rafflev1/vote/setVoteOC",{id: row.id},()=>{
                this.getList();
                this.loading = false;
            }); 
        },
        dateChange(e){
            this.scenariosForm.open_time = dealdate(e[0]);
            this.scenariosForm.close_time = dealdate(e[1])
        },
        // 获取列表数据
        getList(){
            this.loading = true;
            let params = {
                page_size: 10,
                page: this.currentPage
            }
            common.connect("/rafflev1/vote/getVoteList",params,(res)=>{
                res.data.list.forEach(v => {
                    v.state = (v.state == 1);
                    v.imgs = JSON.parse(v.imgs)
                });
                this.total = res.data.count;
                this.tableData = res.data.list;
                this.loading = false;
            });
        },
        // 添加活动
        submitForm(){
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                this.btnLoading = true;
                common.connect("/rafflev1/vote/setVoteAdd",this.scenariosForm,(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getList();
                    this.image = [];
                    this.content = [];
                    this.$refs.ruleForm.resetFields();
                    this.btnLoading = false;
                    this.isAddScenarios = false;
                });
                } else {
                console.log('error submit!!');
                return false;
                }
            });
        },
        // 编辑活动
        submitFormEdit(){
             this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                this.scenariosForm.id = this.editId
                this.btnLoading = true;
                common.connect("/rafflev1/vote/setVoteUpdate",this.scenariosForm,(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getList();
                    this.image = [];
                    this.content = [];
                    this.dateValue = [];
                    this.editId = -1;
                    this.$refs.ruleForm.resetFields();
                    this.btnLoading = false;
                    this.isAddScenarios = false;
                });
                } else {
                console.log('error submit!!');
                return false;
                }
            });
        },
        // 删除
        handleDelete(row){
            this.$confirm('此操作将永久删除该活动, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect("/rafflev1/vote/setVoteDel",{id: row.id},(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getList();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 编辑
        handleEdit(row){
            this.$nextTick(()=>{
                this.editId = row.id;
                this.isAddScenarios = true;
                this.image = row.imgs;
                this.content[0] = row.content;
                this.scenariosForm.name = row.name;
                this.scenariosForm.imgs = row.imgs;
                this.scenariosForm.daily_vnum = row.daily_vnum;
                this.scenariosForm.content = row.content;
                this.scenariosForm.open_time = row.open_time;
                this.scenariosForm.close_time = row.close_time;
                this.$set(this.dateValue,0,row.open_time)
                this.$set(this.dateValue,1,row.close_time)
            })
        },
        // 查看名单
        handleLook(row){
            this.isShow = true;
            common.connect("/rafflev1/vote/getVoteQR",{id: row.id},(res)=>{
                this.lookUrl = res.data.url
            }); 
        },
        delImageList(index){
            this.image.splice(index,1)
        },
        delContentList(){
            this.content.splice(0,1)
        },
        // 关闭弹窗
        handleCloseScenarios(){
            this.isAddScenarios = false;
            this.isShow = false;
            this.$nextTick(()=>{
                this.editId = -1;
                this.image = [];
                this.content = [];
                this.scenariosForm.name = '';
                this.scenariosForm.imgs = [];
                this.scenariosForm.daily_vnum = 1;
                this.scenariosForm.content = '';
                this.scenariosForm.open_time = '';
                this.scenariosForm.close_time = '';
                this.$set(this.dateValue,0,'')
                this.$set(this.dateValue,1,'')
            })
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.getList();
        },
    },
}
</script>

<style lang='scss'>
.box-wrap{
    background: rgb(240, 242, 245);
    // padding: 0 25px;
    max-height: calc(100vh - 50px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 15px 25px;
        .tag{
            width: 4px;
            height: 20px;
            background: #409EFF;
            border-radius: 10px;
        }
        .title{
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px 20px 5px 20px;
        border-radius: 6px;
        margin: 0 25px;
        .add-wrap{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 15px;
        }
        .image{
            height: 40px;
            vertical-align: bottom;
            cursor: pointer;
        }
    }
    .dialog-wrap{
        width: 750px;
        .title{
            font-size: 16px;
            color: #000;
            padding-bottom: 10px;
            margin-left: 20px;
            margin-top: -5px;
        }
        .content-wrap{
            max-height: 80vh;
            overflow-y: auto;
            padding-top: 20px;
            .el-input{
                width: 600px
            }
            .btn-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 20px;
            }
            .upload-wrap-content{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
            .upload-wrap{
                padding-top: 2px;
                .image-wrap{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    width: 100%;
                }
                .fileList-box{
                    width: 100px;
                    height: auto;
                    position: relative;
                    margin-top: 10px;
                    margin-right: 10px;
                    .delFile{
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        border: 1px #ddd solid;
                        background: rgba(100, 100, 100, 0.5);
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 99;
                    }
                    .pic{
                        width: 100%;
                    }
                }
            }
        }
    }
    .dialog-client-wrap{
        width: 750px;
        padding: 0 20px 20px 20px;
        .title{
            font-size: 16px;
            color: #000;
            padding-bottom: 15px;
            margin-top: -5px;
        }
        .btn{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 15px;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .dialog-code-wrap{
        width: 300px;
        .content-wrap{
            display: flex;
            flex-direction: column;
            align-items: center;
            .btn{
                margin: 10px;
            }
        }
    }
    .pagination-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 5px;
    }
}
</style>
