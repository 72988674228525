/*
 * @Descripttion: 时间转换
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-06-28 16:27:09
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-19 11:31:02
 */
// 中国标准时间转正常时间格式
function timestampToTime(timestamp) {
    var Time;
    var date = new Date(timestamp);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    Time = y + '-' + m + '-' + d;
    return Time
}

// 中国标准时间转化成时间戳
function dateToMs(date) {
    let result = new Date(date).getTime();
    return result;
}
function dealdate(dealdate) {
    const date = new Date(dealdate)
    const year = date.getFullYear()   
    let month = date.getMonth() + 1
    month = month<10?"0"+month:month   
    let day = date.getDate()
    day = day<10?"0"+day:day        
    let hour = date.getHours()     
    hour = hour<10?"0"+hour:hour          
    let minute = date.getMinutes() 
    minute = minute<10?"0"+minute:minute  
    let second = date.getSeconds()
    second = second<10?"0"+second:second 
    return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
}
export {
    dateToMs,
    timestampToTime,
    dealdate
}
  
